<template>
  <div class="mb-5" id="contact">
    <Alert
      v-if="showAlert"
      :show="showAlert"
      :type="alertType"
      :message="alertMessage"
    />
    <span class="heading-sec__main">Contato</span>
    <div class="d-flex justify-content-center">
      <div class="contact_container">
        <form @submit.prevent="enviarContato">
          <div class="mx-5 py-4">
            <label for="nome" class="form-label">Nome</label>

            <input
              v-model="contato.nome"
              type="text"
              name="name"
              id="nome"
              placeholder="Digite seu Nome"
              class="form-control"
              :maxlength="50"
              :minlength="5"
              required
            />
            <div class="d-flex justify-content-end">
              <small class="form-text">{{ contato.nome.length }}/50</small>
            </div>
          </div>
          <div class="mb-3 mx-5">
            <label for="email" class="form-label">Email</label>
            <input
              v-model="contato.email"
              type="email"
              name="email"
              id="email"
              placeholder="Digite seu Email"
              class="form-control"
              :maxlength="100"
              :minlength="10"
              required
            />
            <div class="d-flex justify-content-end">
              <small class="form-text"> {{ contato.email.length }}/100</small>
            </div>
          </div>
          <div class="mb-3 mb-3 mx-5">
            <label for="mensagem" class="form-label">Mensagem</label>
            <textarea
              v-model="contato.mensagem"
              name="message"
              id="mensagem"
              class="form-control"
              placeholder="Digite sua mensagem"
              :maxlength="500"
              :minlength="20"
              required
            ></textarea>
            <div class="d-flex justify-content-end">
              <small class="form-text">
                {{ contato.mensagem.length }}/500</small
              >
            </div>
          </div>
          <input type="text" name="honeypot" style="display: none" />
          <input
            type="hidden"
            name="accessKey"
            value="35d5c015-5468-4a0c-855c-855a8f35ac3a"
          />
          <input
            type="hidden"
            name="subject"
            value="Contato Site - cleissontrindade.com"
          />
          <input type="hidden" name="replyTo" value="@" />
          <input type="hidden" name="redirectTo" value="" />
          <div class="d-flex justify-content-end mx-5">
            <button type="submit" value="Submit" class="btn btn-primary btn-lg">
              Enviar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios"
  import Alert from "../components/AlertPage.vue"

  export default {
    components: {
      Alert,
    },
    data() {
      return {
        showAlert: false,
        alertType: "",
        alertMessage: "",
        contato: {
          nome: "",
          email: "",
          mensagem: "",
          accessKey: "35d5c015-5468-4a0c-855c-855a8f35ac3a",
          subject: "Contato Site - cleissontrindade.com",
          replyTo: "@",
          redirectTo: "",
        },
      }
    },
    methods: {
      async enviarContato() {
        try {
          const formData = new FormData()
          formData.append("name", this.contato.nome)
          formData.append("email", this.contato.email)
          formData.append("message", this.contato.mensagem)
          formData.append("honeypot", "")
          formData.append("accessKey", this.contato.accessKey)
          formData.append("subject", this.contato.subject)
          formData.append("replyTo", this.contato.email)
          formData.append("redirectTo", this.contato.redirectTo)

          const response = await axios.post(
            "https://api.staticforms.xyz/submit",
            formData,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )

          if (response.status === 200) {
            this.showAlert = true
            this.alertType = "alert-success"
            this.alertMessage = "E-mail enviado com sucesso!"
            this.resetForm()
            setTimeout(() => {
              this.showAlert = false
            }, 5000)
          }
        } catch (error) {
          this.showAlert = true
          this.alertType = "alert-error"
          this.alertMessage =
            "Ocorreu um erro ao enviar o e-mail. Por favor, tente novamente."
        }
      },
      resetForm() {
        this.contato.nome = ""
        this.contato.email = ""
        this.contato.mensagem = ""
      },
    },
  }
</script>

<style>
  .contact_container {
    width: 50%;
    height: 600px;
    background-color: silver;
    border-radius: 50px;
  }
  textarea {
    height: 175px;
    resize: none;
  }
  @media only screen and (max-width: 767px) {
    .contact_container {
      width: 90%;
    }
  }
</style>
