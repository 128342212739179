<template>
  <div class="d-flex justify-content-center">
    <div v-if="show" class="alert" :class="type" role="alert">
      {{ message }}
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      show: Boolean,
      type: String,
      message: String,
    },
  }
</script>

<style>
  .alert {
    position: fixed;
    top: 15%;
    padding: 15px;
    border-radius: 5px;
    color: white;
    z-index: 100;
  }
  .alert-success {
    background-color: green;
  }
  .alert-error {
    background-color: red;
  }
</style>
