<template>
  <div>
    <NavBar />
    <InitPage />
    <AboutPage />
    <Timeline />
    <ContactPage />
    <FooterPage />
  </div>
</template>

<script>
  import NavBar from "@/components/NavBar.vue"
  import InitPage from "@/components/InitPage.vue"
  import AboutPage from "@/components/AboutPage.vue"
  import Timeline from "@/components/TimeLine.vue"
  import ContactPage from "@/components/ContactPage.vue"
  import FooterPage from "@/components/FooterPage.vue"

  export default {
    name: "HomePage",
    components: {
      Timeline,
      ContactPage,
      FooterPage,
      NavBar,
      InitPage,
      AboutPage,
    },
    props: {
      msg: String,
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
