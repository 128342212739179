<template>
  <footer class="footer bg-dark text-light text-center">
    <div class="container">
      <div class="d-flex justify-content-center">
        <img src="../assets/logo.png" width="40px" height="40px" />
        <p class="mt-2 mx-2">Cleisson Trindade</p>
      </div>
      <div class="mt-4">
        <ul class="ftco-footer-social p-0">
          <li class="ftco-animate">
            <a
              href="https://www.linkedin.com/in/cleisson-trindade-03a929b0/"
              data-toggle="tooltip"
              data-placement="top"
              title="Linkedin"
            >
              <Icon icon="devicon:linkedin" width="25px" height="25px"
            /></a>
          </li>
          <li class="ftco-animate">
            <a
              href="https://github.com/cleissont"
              data-toggle="tooltip"
              data-placement="top"
              title="Github"
            >
              <Icon
                icon="devicon:github"
                class="icon"
                width="25px"
                height="25px"
                color="white"
            /></a>
          </li>
        </ul>
      </div>
      <p class="mt-4">
        Copyright ©2023 All rights reserved |
        <a
          href="https://www.cleissontrindade.com/"
          style="text-decoration: none"
          class="text-light"
        >
          Cleisson Trindade</a
        >
      </p>
    </div>
  </footer>
</template>
<script>
  import { Icon } from "@iconify/vue2"
  export default {
    name: "FooterPage",
    components: {
      Icon,
    },
  }
</script>
<style>
  .footer {
    padding: 40px 0;
  }

  .footer p {
    font-size: 18px;
  }

  .footer nav {
    font-size: 14px;
  }

  .footer i {
    font-size: 24px;
  }
  .ftco-footer-social li {
    list-style: none;
    margin: 0 10px 0 0;
    display: inline-block;
  }
  .nav-text {
    text-decoration: none;
    font-size: 20px;
    padding: 10px;
  }
  .ftco-footer-social li a {
    height: 40px;
    width: 40px;
    display: block;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 50%;
    position: relative;
  }
  .ftco-footer-social li a span {
    position: absolute;
    font-size: 20px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .ftco-footer-social li a:hover {
    color: #fff;
  }
  .circle {
    width: 50px;
    height: 50px;
    border: 2px solid white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon {
    font-size: 24px;
    background-color: #fff;
  }
</style>
