<template>
  <div class="header">
    <div class="text-center text-into">
      <div class="text-into">
        <p class="text-light font-text">Olá, eu sou Cleisson</p>
      </div>
      <div class="mt-5 mb-5">
        <vue-typer
          :text="title.nome"
          :repeat="Infinity"
          :shuffle="false"
          initial-action="typing"
          :pre-type-delay="80"
          :type-delay="120"
          :pre-erase-delay="3000"
          :erase-delay="500"
          erase-style="clear"
          :erase-on-complete="false"
          caret-animation="solid"
        ></vue-typer>
      </div>
      <div class="arrow-down mt-5">
        <img src="../assets/arrow-down.svg" height="80px" />
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "InitPage",
    data() {
      return {
        title: {
          nome: "Front End Developer",
        },
      }
    },
  }
</script>
<style>
  .header {
    margin-top: 56px;
    background-image: url("../assets/protruding-squares.png");
    background-size: 40px;
    background-repeat: round;
    height: 500px;
  }
  .vue-typer {
    font-family: monospace;
    font-weight: bold;
    font-size: 40px;
  }

  .vue-typer .custom.char {
    color: #fff;
    font-weight: bold;
  }
  .vue-typer .custom.char.selected {
    background-color: #fff;
  }

  .vue-typer .custom.caret {
    background-color: #000;
  }
  .text-into {
    padding-top: 50px;
  }
  .font-text {
    font-weight: bold;
    font-size: 45px;
    font-family: cursive;
  }
  .arrow-down {
    padding-right: 60px;
    position: relative;
    margin-top: 100px !important;
  }

  .arrow-down img {
    position: absolute;
    animation: moveUpDown 500ms infinite alternate;
  }
  .text {
    color: #fff !important;
  }
  @keyframes moveUpDown {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-20px);
    }
  }
</style>
