<template>
  <div class="container mt-5">
    <span class="heading-sec__main" id="experience">Minha experiência</span>
    <div class="main-timeline">
      <!-- start experience section-->
      <div class="timeline">
        <div class="icon"></div>
        <div class="date-content">
          <div class="date-outer">
            <span class="date">
              <span class="month">Atual</span>
              <span class="year">2023</span>
            </span>
          </div>
        </div>
        <div class="timeline-content">
          <h5 class="title">Engenheiro de Software - Machiron</h5>
          <p class="description">
            Engenheiro de Software especializado em desenvolvimento web com
            amplo domínio de Vue.js, e integrações de API. Habilidoso em testes
            unitários com Jasmine e focado em IA na odontologia. Fundindo
            habilidades técnicas robustas com criatividade, forneço soluções web
            inovadoras e eficazes.
          </p>
        </div>
      </div>
      <!-- end experience section-->

      <!-- start experience section-->
      <div class="timeline">
        <div class="icon"></div>
        <div class="date-content">
          <div class="date-outer">
            <span class="date">
              <span class="month">1 Ano</span>
              <span class="year">2022</span>
            </span>
          </div>
        </div>
        <div class="timeline-content">
          <h5 class="title">Desenvolvedor Front End - Minsait</h5>
          <p class="description">
            Experiência no desenvolvimento web com TypeScript e Angular 12+,
            incluindo integração de micro serviços via APIs Restful.
            Proficiência em criação de protótipos no Figma e desenvolvimento Low
            Code (APPIAN). Uso do Azure DevOps para gestão de projetos,
            implementação de testes unitários com Jasmine e domínio das
            Metodologias Ágeis e Scrum para entregas eficazes.
          </p>
        </div>
      </div>
      <!-- end experience section-->

      <!-- start experience section-->
      <div class="timeline">
        <div class="icon"></div>
        <div class="date-content">
          <div class="date-outer">
            <span class="date">
              <span class="month">5 Anos</span>
              <span class="year">2017</span>
            </span>
          </div>
        </div>
        <div class="timeline-content">
          <h5 class="title">Desenvolvedor Front End - 2Mundos</h5>
          <p class="description">
            Experiência abrangente em desenvolvimento e manutenção de sites,
            empregando HTML5, CSS, SASS, JavaScript, TypeScript, PHP e
            WordPress. Especialização em frameworks Vue.js, Angular 8+ e
            Laravel. Conexão de micro serviços via APIs Restful, otimização de
            SEO e visibilidade de sites. Profundo conhecimento em Metodologias
            Ágeis e Scrum para desenvolvimento ágil.
          </p>
        </div>
      </div>
      <!-- end experience section-->

      <!-- start experience section-->
      <div class="timeline">
        <div class="icon"></div>
        <div class="date-content">
          <div class="date-outer">
            <span class="date">
              <span class="month">2 Anos</span>
              <span class="year">2015</span>
            </span>
          </div>
        </div>
        <div class="timeline-content">
          <h5 class="title">Analista CRM - Engage CRM</h5>
          <p class="description">
            Experiência em desenvolvimento e manutenção de sites em WordPress,
            empregando HTML5, CSS3, JavaScript, jQuery e PHP. Aprimoramento de
            SEO e desempenho de sites. Proficiência na criação de e-mails de
            marketing utilizando Photoshop e Illustrator.
          </p>
        </div>
      </div>
      <!-- end experience section-->
    </div>
  </div>
</template>
<script>
  export default {
    name: "TimeLine",
  }
</script>
<style>
  body {
    background-color: #f7f7f7;
    margin-top: 20px;
  }

  .main-timeline {
    margin-top: 100px;
    position: relative;
  }

  .main-timeline:before {
    content: "";
    display: block;
    width: 2px;
    height: 100%;
    background: #c6c6c6;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  .main-timeline .timeline {
    margin-bottom: 40px;
    position: relative;
  }

  .main-timeline .timeline:after {
    content: "";
    display: block;
    clear: both;
  }

  .main-timeline .icon {
    width: 18px;
    height: 18px;
    line-height: 18px;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .main-timeline .icon:before,
  .main-timeline .icon:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.33s ease-out 0s;
  }

  .main-timeline .icon:before {
    background: #fff;
    border: 2px solid #232323;
    left: -3px;
  }

  .main-timeline .icon:after {
    border: 2px solid #c6c6c6;
    left: 3px;
  }

  .main-timeline .timeline:hover .icon:before {
    left: 3px;
  }

  .main-timeline .timeline:hover .icon:after {
    left: -3px;
  }

  .main-timeline .date-content {
    width: 50%;
    float: left;
    margin-top: 22px;
    position: relative;
  }

  .main-timeline .date-content:before {
    content: "";
    width: 36.5%;
    height: 2px;
    background: #c6c6c6;
    margin: auto 0;
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
  }

  .main-timeline .date-outer {
    width: 125px;
    height: 125px;
    font-size: 16px;
    text-align: center;
    margin: auto;
    z-index: 1;
  }

  .main-timeline .date-outer:before,
  .main-timeline .date-outer:after {
    content: "";
    width: 125px;
    height: 125px;
    margin: 0 auto;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transition: all 0.33s ease-out 0s;
  }

  .main-timeline .date-outer:before {
    background: #fff;
    border: 2px solid #232323;
    left: -6px;
  }

  .main-timeline .date-outer:after {
    border: 2px solid #c6c6c6;
    left: 6px;
  }

  .main-timeline .timeline:hover .date-outer:before {
    left: 6px;
  }

  .main-timeline .timeline:hover .date-outer:after {
    left: -6px;
  }

  .main-timeline .date {
    width: 100%;
    margin: auto;
    position: absolute;
    top: 27%;
    left: 0;
  }

  .main-timeline .month {
    font-size: 18px;
    font-weight: 700;
  }

  .main-timeline .year {
    display: block;
    font-size: 30px;
    font-weight: 700;
    color: #232323;
    line-height: 36px;
  }

  .main-timeline .timeline-content {
    width: 50%;
    padding: 20px 0 20px 50px;
    float: right;
  }

  .main-timeline .title {
    font-size: 19px;
    font-weight: 700;
    line-height: 24px;
    margin: 0 0 15px 0;
  }

  .main-timeline .description {
    margin-bottom: 0;
  }

  .main-timeline .timeline:nth-child(2n) .date-content {
    float: right;
  }

  .main-timeline .timeline:nth-child(2n) .date-content:before {
    left: 10px;
  }

  .main-timeline .timeline:nth-child(2n) .timeline-content {
    padding: 20px 50px 20px 0;
    text-align: right;
  }

  @media only screen and (max-width: 991px) {
    .main-timeline .date-content {
      margin-top: 35px;
    }
    .main-timeline .date-content:before {
      width: 22.5%;
    }
    .main-timeline .timeline-content {
      padding: 10px 0 10px 30px;
    }
    .main-timeline .title {
      font-size: 17px;
    }
    .main-timeline .timeline:nth-child(2n) .timeline-content {
      padding: 10px 30px 10px 0;
    }
  }

  @media only screen and (max-width: 767px) {
    .main-timeline:before {
      margin: 0;
      left: 7px;
    }
    .main-timeline .timeline {
      margin-bottom: 20px;
    }
    .main-timeline .timeline:last-child {
      margin-bottom: 0;
    }
    .main-timeline .icon {
      margin: auto 0;
    }
    .main-timeline .date-content {
      width: 95%;
      float: right;
      margin-top: 0;
    }
    .main-timeline .date-content:before {
      display: none;
    }
    .main-timeline .date-outer {
      width: 110px;
      height: 110px;
    }
    .main-timeline .date-outer:before,
    .main-timeline .date-outer:after {
      width: 110px;
      height: 110px;
    }
    .main-timeline .date {
      top: 30%;
    }
    .main-timeline .year {
      font-size: 24px;
    }
    .main-timeline .timeline-content,
    .main-timeline .timeline:nth-child(2n) .timeline-content {
      width: 95%;
      text-align: center;
      padding: 10px 0;
    }
    .main-timeline .title {
      margin-bottom: 10px;
    }
  }
</style>
