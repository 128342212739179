<template>
  <nav class="navbar fixed-top navbar-expand-lg bg-body-tertiary">
    <div class="container-fluid">
      <a class="navbar-brand text-light mx-5" href="#">Cleisson Trindade</a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <!-- <a href="#" class="nav-text text-light">Home</a> -->
          </li>
          <li class="nav-item">
            <!-- <a href="#" class="nav-text text-light">Sobre</a> -->
          </li>
          <li class="nav-item">
            <!-- <a href="#" class="nav-text text-light">Contato</a> -->
          </li>
        </ul>
        <div class="form-inline my-2 my-lg-0 mx-5">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a href="#" class="nav-text text-light">Home</a>
            </li>
            <li class="nav-item">
              <a href="#about" class="nav-text text-light">Sobre</a>
            </li>
            <li class="nav-item">
              <a href="#experience" class="nav-text text-light">Experiência</a>
            </li>
            <li class="nav-item">
              <a href="#contact" class="nav-text text-light">Contato</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
  export default {}
</script>
<style>
  .navbar {
    background-color: #212529 !important;
  }

  .navbar-toggler-icon {
    background-color: #fff;
  }
  .navbar-toggler {
    background-color: #fff;
  }
  .content {
    display: flex;
    justify-content: space-between;
  }
  .nav-text {
    text-decoration: none;
    font-size: 20px;
    padding: 10px;
  }
</style>
