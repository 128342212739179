import "bootstrap/dist/css/bootstrap.css"
import "bootstrap/dist/js/bootstrap.js"
import VueTyperPlugin from "vue-typer"
import Vue from "vue"
import App from "./App.vue"

Vue.config.productionTip = false

Vue.use(VueTyperPlugin)

new Vue({
  render: (h) => h(App),
}).$mount("#app")
