<template>
  <div class="container" id="about">
    <span class="heading-sec__main">Sobre mim</span>
    <div class="row">
      <div class="col-sm">
        <div class="mt-5">
          <h2>Seja bem-vindo!</h2>
          <div class="mt-5">
            <p>
              <strong>Desenvolvedor Web Frontend</strong> especializado na
              criação de interfaces funcionais para websites e aplicações web,
              com o objetivo de impulsionar o sucesso do projeto.
              <!-- Você pode
            explorar alguns dos projetos que realizei na seção de
            "<strong>Projetos</strong>". -->
            </p>

            <p>
              Além disso, tenho paixão por compartilhar o conhecimento que
              acumulei ao longo dos anos no campo do
              <strong>Desenvolvimento Web</strong>, para contribuir com a
              Comunidade Dev. Convido você a se conectar comigo e a me
              acompanhar no
              <strong
                ><a
                  href="https://www.linkedin.com/in/cleisson-trindade-03a929b0/"
                  >LinkedIn</a
                ></strong
              >.
            </p>

            <p>
              Caso você tenha uma oportunidade alinhada às minhas habilidades e
              experiência, ficarei muito grato se
              <a href="#contact" text-decoration:none
                ><strong>entrar em contato</strong></a
              >.
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm text-center mt-5">
        <h2 class="mb-5">My Skills</h2>
        <div class="row d-flex justify-content-center text">
          <div class="col-sm-4 col-md-2 mx-2 mt-3">
            <div class="">
              <div class="">
                <img src="../assets/html-5.png" width="60px" height="60px" />
                <span>HTML5</span>
              </div>
            </div>
          </div>
          <div class="col-sm-4 col-md-2 mx-2 mt-3">
            <div>
              <div>
                <img src="../assets/css.png" width="60px" height="60px" />
                <span>CSS3</span>
              </div>
            </div>
          </div>
          <div class="col-sm-4 col-md-2 mx-2 mt-3">
            <div>
              <div>
                <img
                  src="../assets/java-script.png"
                  width="60px"
                  height="60px"
                />
                <span>JavaScript</span>
              </div>
            </div>
          </div>
          <div class="col-sm-4 col-md-2 mx-2 mt-3">
            <div>
              <div>
                <img
                  src="../assets/typescript.png"
                  width="60px"
                  height="60px"
                />
                <span>TypeScript</span>
              </div>
            </div>
          </div>
          <div class="col-sm-4 col-md-2 mx-2 mt-3">
            <div>
              <div>
                <img src="../assets/sass.png" width="60px" height="60px" />
                <span>Sass</span>
              </div>
            </div>
          </div>
          <div class="col-sm-4 col-md-2 mx-2 mt-3">
            <div>
              <div>
                <img src="../assets/vue-js.png" width="60px" height="60px" />
                <span>Vue</span>
              </div>
            </div>
          </div>
          <div class="col-sm-4 col-md-2 mx-2 mt-3">
            <div>
              <div>
                <img src="../assets/angular.png" width="60px" height="60px" />
                <span>Angular</span>
              </div>
            </div>
          </div>
          <div class="col-sm-4 col-md-2 mx-2 mt-3">
            <div>
              <div>
                <img src="../assets/wordpress.png" width="60px" height="60px" />
                <span>Wordpress</span>
              </div>
            </div>
          </div>
          <div class="col-sm-4 col-md-2 mx-2 mt-3">
            <div>
              <div>
                <img src="../assets/php.png" width="60px" height="60px" />
                <span>PHP</span>
              </div>
            </div>
          </div>
          <div class="col-sm-4 col-md-2 mx-2 mt-3">
            <div>
              <div class="text-center">
                <img
                  src="../assets/github-sign.png"
                  width="60px"
                  height="60px"
                />
                <span class="mx-2">Github</span>
              </div>
            </div>
          </div>
          <div class="col-sm-4 col-md-2 mx-2 mt-3">
            <div>
              <div>
                <img src="../assets/devices.png" width="60px" height="60px" />
                <span>Responsive Design</span>
              </div>
            </div>
          </div>
          <div class="col-sm-4 col-md-2 mx-2 mt-3">
            <div>
              <div>
                <img src="../assets/seo.png" width="60px" height="60px" />
                <span>SEO</span>
              </div>
            </div>
          </div>
          <div class="col-sm-4 col-md-2 mx-2 mt-3">
            <div>
              <div>
                <img src="../assets/nuxt_logo.png" width="80px" height="60px" />
                <span>Nuxt.js</span>
              </div>
            </div>
          </div>
          <div class="col-sm-4 col-md-2 mx-2 mt-3">
            <div>
              <div>
                <img
                  src="../assets/tailwindcss-icon.svg"
                  width="80px"
                  height="60px"
                />
                <span>Tailwind</span>
              </div>
            </div>
          </div>
          <div class="col-sm-4 col-md-2 mx-2 mt-3">
            <div>
              <div>
                <img src="../assets/docker.png" width="80px" height="60px" />
                <span>Docker</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {}
</script>
<style>
  .heading-sec__main {
    display: block;
    font-size: 40px;
    text-transform: uppercase;
    letter-spacing: 1px;
    letter-spacing: 2px;
    text-align: center;
    margin-top: 100px;
    margin-bottom: 100px;
    position: relative;
    color: #111;
    font-family: "Kumbh Sans", sans-serif;
  }

  .heading-sec__main::after {
    content: "";
    position: absolute;
    top: calc(100% + 1.5rem);
    height: 5px;
    width: 3rem;
    background: #4a6de0;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
  }
</style>
